import React from 'react';
import { Flex } from '@chakra-ui/core';
import { NavButton } from '@zeta';

const GetStartedBtn = ({ isMobile = false }) => {
  const getStarted = () => {
    window.location.href = 'https://askzeta.app.link/getstarted';
  };
  return (
    <Flex style={{ marginLeft: isMobile ? undefined : '2rem' }}>
      <NavButton transparent variant="primary" onClick={getStarted} text="Get started" />
    </Flex>
  );
};
export default GetStartedBtn;
