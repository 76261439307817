import React from 'react';
import { Flex, Text, Image, Heading, Box } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import Step1Icon from '../assets/steps/Step1Icon.png';
import Step2Icon from '../assets/steps/Step2Icon.png';
import Step3Icon from '../assets/steps/Step3Icon.png';
import Step4Icon from '../assets/steps/Step4Icon.png';
import Wave1 from '../assets/Wave1';
import Wave2 from '../assets/Wave2';

import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';
import HowItWorksItem from '../zeta/HowItWorksItem';

const HowItWorks = ({ variant }) => {
  const data = {};
  const JointHowItWorks1 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks1.svg`;
  const PersonalHowItWorks2 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-howItWorks2.svg`;
  const JointHowItWorks3 = `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks3.svg`;
  const SoloPassHowItWorks3 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-howItWorks-3.svg`;

  if (variant === 'joint-accounts') {
    data.steps = [
      'Tell us about you and your relationship',
      'Invite your partner so they can confirm their info.',
      'You each get your own Zeta Joint Card.',
      'Start spending and saving, as a team!',
    ];

    data.images = [
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step1.png`,
      Step2Icon,
      Step3Icon,
      `${cloudfrontBaseUrl}/personal-accounts/how-it-works/step4.png`,
    ];
  } else if (variant === 'personal-accounts') {
    data.steps = [
      'Open your Zeta joint account',
      'Add on a personal account from your team drop down',
      'You can request a free personal debit card',
      'Start spending & saving privately',
    ];
  } else if (variant === 'solo-pass') {
    data.steps = [
      `Sign up using your Solo Pass code`,
      'Open your personal account in minutes',
      'You can request a free personal debit card',
      'Start spending & saving',
    ];
  }

  if (variant === 'solo-pass' || variant === 'personal-accounts') {
    data.images = [
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks1.svg`,
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks2.svg`,
      `${cloudfrontBaseUrl}/homepage-24/joint-accounts/joint-howItWorks3.svg`,
    ];
  }

  return (
    <Flex py={[10, 24, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[10, 10, 16]}
        px={[4, 4, 0]}
      >
        <H2>How it works</H2>
        <H4 mt={4} center>
          Ready to get started? Here's what to expect.
        </H4>
      </Flex>

      <Flex
        align="flex-start"
        justify={['center', 'center', 'center', 'flex-end']}
        direction={['column', 'column', 'column', 'row']}
        marginLeft={[0, 0, 0, '5%']}
        px={[4, 4, 4, 0]}
      >
        <HowItWorksItem
          image={JointHowItWorks1}
          title="Sign up"
          body="Use your personal Solo Pass code to sign up"
          connector="even"
          itemCount={4}
        />

        <HowItWorksItem
          image={PersonalHowItWorks2}
          title="Open your account"
          body="Open your personal account in minutes"
          connector="odd"
          itemCount={4}
        />

        <HowItWorksItem
          image={SoloPassHowItWorks3}
          title="Request a card"
          body="You can request a free personal debit card"
          connector="even"
          itemCount={4}
        />
        <HowItWorksItem
          image={JointHowItWorks3}
          title="Spend & save privately"
          body="Receive a debit card to start spending & saving"
          connector="none"
          itemCount={4}
        />
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
