import React from 'react';
import { Flex, Image } from '@chakra-ui/core';
import Link from './Link';
import { cloudfrontBaseUrl } from '../lib/consts';

const LogoLink = ({ homeNav, lightMode, legal, mobile = false }) => {
  return (
    <Link className="nav-link" noNewTab to="/">
      <Flex ml={legal ? [0, -1] : [0, 0]} direction="column" justify="center">
        <Image
          loading="eager"
          alt=""
          src={
            homeNav
              ? `${cloudfrontBaseUrl}/logos/zeta-logo-lightmode.svg`
              : lightMode
              ? `${cloudfrontBaseUrl}/logos/zeta-logo-lightmode.svg`
              : `${cloudfrontBaseUrl}/logos/zeta-logo-darkmode.svg`
          }
        />
      </Flex>
    </Link>
  );
};
export default LogoLink;
