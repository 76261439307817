import React, { useState } from 'react';
import { Box, Flex, Text, Image, Button, Heading, Collapse } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import ExpandIcon from '../assets/buttons/Expand.png';
import CollapseIcon from '../assets/buttons/Collapse.png';

import { H2, H4 } from '@typography';
import ContentContainer from '../ContentContainer';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';

const FAQs = ({ content }) => {
  const [showKey, setShowKey] = useState('who');
  const handleToggle = ({ key }) => {
    if (showKey === key) {
      setShowKey(null);
    } else {
      setShowKey(key);
    }
  };

  const onClick = () => {
    window.location.href = '/help';
  };

  return (
    <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH} width={['100%']}>
      <Flex paddingY={[10, 10, 24, 24]} paddingX={[6, 12, 12, 6]} direction="column">
        <Flex
          justify="center"
          align="center"
          direction="column"
          textAlign="center"
          mb={0}
        >
          <H2>Frequently asked questions</H2>
          <H4 mt={4} center>
            We're here to answer any questions you might have.
          </H4>
        </Flex>

        <Flex paddingTop={['36px']} paddingX={[0]} marginX={[0]} direction="column">
          {content.map((faq, idx) => (
            <Flex
              key={`faq-${idx}`}
              mb={3}
              py={3}
              borderBottom="divider"
              direction="column"
              cursor="pointer"
              onClick={() => handleToggle({ key: faq.key })}
            >
              <Flex pb={3} justify="space-between" align="center">
                <Heading
                  as="h3"
                  fontSize={'16px'}
                  fontWeight={theme.fontWeights.semibold}
                  pr={4}
                >
                  {faq.heading}
                </Heading>
                <Box
                  width={['1rem', '1rem', '1.75rem']}
                  height="1.75rem"
                  minWidth="1.25rem"
                  minHeight="1.25rem"
                  as="button"
                >
                  <Image src={showKey === faq.key ? CollapseIcon : ExpandIcon} />
                </Box>
              </Flex>

              <Flex>
                <Collapse
                  maxW="40rem"
                  py={5}
                  startingHeight={0}
                  isOpen={showKey === faq.key}
                  fontSize={'16px'}
                >
                  {faq.containsHTML ? (
                    <>
                      <br />
                      <Text dangerouslySetInnerHTML={{ __html: faq.body }}></Text>
                    </>
                  ) : (
                    faq.body
                  )}
                  {faq.disclosure?.length && faq.containsHTML ? (
                    <>
                      <br />
                      <Text dangerouslySetInnerHTML={{ __html: faq.disclosure }}></Text>
                    </>
                  ) : null}
                  {faq.disclosure?.length && !faq.containsHTML ? faq.disclosure : null}
                </Collapse>
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex mt={6} justify="center">
          <Button
            style={{
              backgroundColor: colors.background,
              padding: '1rem',
              borderRadius: '0px',
            }}
            px={4}
            py={2}
            onClick={onClick}
          >
            <Text
              style={{
                display: 'inline-block',
                lineHeight: '22px',
              }}
              fontWeight={theme.fontWeights.semibold}
              color={colors.greenPea}
            >
              See All FAQs
            </Text>
          </Button>
        </Flex>
      </Flex>
    </ContentContainer>
  );
};

export default FAQs;
