import React from 'react';
import { Flex } from '@chakra-ui/core';
import Helmet from 'react-helmet';

import { colors } from '@theme';

const Metadata = props => {
  const { statusBarColor } = props;
  const helmet = React.useMemo(
    () => (
      <Helmet>
        <title>Personal bank account: Secure your finances with Zeta</title>
        <link rel="canonical" href="https://www.askzeta.com/personal-bank-account" />
        <meta
          name="description"
          content="Secure your individual finances with a personal bank account from Zeta. Experience financial independence while benefiting from Zeta's innovative features."
        />
        <meta
          property="og:description"
          content="Secure your individual finances with a personal bank account from Zeta. Experience financial independence while benefiting from Zeta's innovative features."
        />
        <meta
          name="keywords"
          content="personal account, personal bank account, banking, combining finances, individual bank account, zeta features, zeta account benefits"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Zeta | Master Money as a Team" />
        <meta property="og:site_name" content="Zeta | Master Money as a Team" />
        <meta property="og:url" content="https://www.askzeta.com/personal-bank-account" />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://d142whjg6dsdg2.cloudfront.net/logos/zeta-circle.png"
        />
        <meta property="fb:app_id" content="2316257168617802" />
        <meta name="theme-color" content={statusBarColor} />
      </Helmet>
    ),
    [],
  );
  return <Flex>{helmet}</Flex>;
};

export default Metadata;
