import React from 'react';
import { Flex, Box, Text, Image, useMediaQuery } from '@chakra-ui/core';
import {
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
  APP_CONTENT_WIDE_WIDTH,
} from '../../styles/sizes';
import { colors } from '@theme';

const TextWithImageRow = ({
  imageOnRight = false,
  image,
  mobileImage,
  mobileBackground, // this is designed to be a single color used in a radial gradient from color to transparent for mobile only
  fullWidthMobileBackground = false,
  smallerText = false,
  tag = '',
  title = '',
  body = '',
}) => {
  // Smallest breakpoint
  const [isSmallestBreakpoint] = useMediaQuery(`(max-width: 30rem)`);
  // Commonly use mobile breakpoint. This is the point in which most elements render as columns.
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_MOBILE_WIDTH})`);
  const [isColumnBreakpoint] = useMediaQuery(
    `(max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH})`,
  );
  const imageSize = '55%';
  const contentSize = '45%';
  const contentOffsetPadding = smallerText ? '30px' : '45px';
  const offset = '50px';
  const backgroundStyle = {
    background: `-moz-radial-gradient(${mobileBackground}, transparent 65%)`, // FF 3.6+
    background: `-webkit-gradient(radial, center center, center center, color-stop(0%, ${mobileBackground}), color-stop(100%, #ffffff00))`, // Safari 4+, Chrome 2+
    background: `-webkit-radial-gradient(${mobileBackground}, transparent 65%)`, // Safari 5.1+, Chrome 10+
    background: `-o-radial-gradient(${mobileBackground}, transparent 65%)`, // Opera 11.10
    background: `radial-gradient(${mobileBackground}, transparent 65%)`, // the standard
  };

  // If fullWidthMobileBackground is true, we use the smallestBreakpoint to swap in the bg. Otherwise
  // we use the regular mobile breakpoint to bring in the bg.
  return (
    <Box>
      <Flex
        borderRadius={['15px', '15px', '15px', 0]}
        direction="row"
        justify={
          imageOnRight
            ? ['center', 'center', 'center', 'space-between']
            : ['center', 'center', 'center', 'space-between']
        }
        align="center"
        wrap={
          imageOnRight
            ? ['wrap-reverse', 'wrap-reverse', 'wrap-reverse', 'no-wrap']
            : ['wrap', 'wrap', 'wrap', 'no-wrap']
        }
        paddingX={fullWidthMobileBackground ? [0, 8, 10, 0] : [6, 8, 10, 0]}
        paddingTop={[0, 0, 0, 0]}
        paddingBottom={[24, 16, 8, 0]}
        maxWidth={APP_CONTENT_WIDE_WIDTH}
      >
        {imageOnRight ? null : (
          <Flex
            alignContent="center"
            width={['100%', '100%', '100%', `calc(${imageSize} + ${offset})`]}
            justify={['center', 'center', 'center', 'flex-start']}
            align="end"
            paddingBottom={['24px', '24px', '24px', 0]}
            background={
              mobileBackground && fullWidthMobileBackground && isSmallestBreakpoint
                ? backgroundStyle
                : mobileBackground && !fullWidthMobileBackground && isMobile
                ? backgroundStyle
                : ''
            }
          >
            <Image
              mt={0}
              width={'100%'}
              maxWidth={
                fullWidthMobileBackground
                  ? ['100%', '500px', '500px', '700px']
                  : ['90%', '500px', '500px', '700px']
              }
              src={
                // We handle fullWidthMobile images differently because they are designed
                // for mobile views and don't look as good on the in-between screen sizes.
                mobileImage && fullWidthMobileBackground && isSmallestBreakpoint
                  ? mobileImage
                  : mobileImage && !fullWidthMobileBackground && isMobile
                  ? mobileImage
                  : image
              }
            />
          </Flex>
        )}

        <Flex
          width={
            fullWidthMobileBackground
              ? ['90%', '100%', '100%', `${contentSize}`]
              : imageOnRight
              ? ['100%', '100%', '100%', `${contentSize}`]
              : ['100%', '100%', '100%', `calc(${contentSize} - ${offset})`]
          }
          paddingLeft={[0, 0, 0, imageOnRight ? 0 : `${contentOffsetPadding}`]}
          paddingRight={[0, 0, 0, imageOnRight ? `${contentOffsetPadding}` : 0]}
          direction="column"
          justify={['left', 'left', 'left', 'center']}
          textAlign={['left', 'left', 'left', 'left']}
          zIndex={10}
        >
          <Text
            textAlign={['center', 'center', 'center', 'left']}
            fontSize={'18px'}
            fontWeight={['400', '400', '400', '600']}
            width="100%"
            lineHeight={[1.2, 1.2, 1.2, 2.5]}
            color={colors.darkGray}
            textTransform="uppercase"
          >
            {tag}
          </Text>
          <Text
            textAlign={['center', 'center', 'center', 'left']}
            paddingTop={[isColumnBreakpoint ? '8px' : 0]}
            fontWeight="600"
            fontSize={
              smallerText
                ? ['24px', '24px', '24px', '32px']
                : ['24px', '24px', '24px', '40px']
            }
            lineHeight={1.2}
            color={colors.darkGray}
            as="h3"
          >
            {title}
          </Text>

          <Box
            display={['flex', 'flex', 'flex', 'block']}
            justifyContent={'center'}
            width={'100%'}
          >
            <Text
              lineHeight={[1.5, 1.5, 1.5, 1.7]}
              fontSize={['16px', '16px', '16px', '18px']}
              color={colors.grayGray900}
              textAlign={['center', 'center', 'center', 'left']}
              fontWeight={'400'}
              width={['100%', '90%', '60%', '100%']}
              pt="24px"
            >
              {body}
            </Text>
          </Box>
        </Flex>

        {imageOnRight ? (
          <Flex
            width={['100%', '100%', '100%', `${imageSize}`]}
            justify={['center', 'center', 'center', 'flex-start']}
            alignContent="center"
            align="end"
            paddingBottom={['24px', '24px', '24px', 0]}
            background={
              mobileBackground && fullWidthMobileBackground && isSmallestBreakpoint
                ? backgroundStyle
                : mobileBackground && !fullWidthMobileBackground && isMobile
                ? backgroundStyle
                : ''
            }
          >
            <Image
              mt={0}
              width={'100%'}
              maxWidth={
                fullWidthMobileBackground
                  ? ['100%', '500px', '500px', '700px']
                  : ['90%', '500px', '500px', '700px']
              }
              src={
                mobileImage && fullWidthMobileBackground && isSmallestBreakpoint
                  ? mobileImage
                  : mobileImage && !fullWidthMobileBackground && isMobile
                  ? mobileImage
                  : image
              }
            />
          </Flex>
        ) : null}
      </Flex>
    </Box>
  );
};

export default TextWithImageRow;
