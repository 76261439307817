import React from 'react';
import { Box, Flex } from '@chakra-ui/core';

import { colors } from '@theme';
import { H2, H4 } from '@typography';
import { cloudfrontBaseUrl } from '../../lib/consts';
import TextWithImageRowDarkBg from '../zeta/TextWithImageRowDarkBg';
import Link from '../../Navigation/Link';

const KeyFeatures = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  const soloPassFeatures1 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features1.png`;
  const soloPassFeatures2 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features2.png`;
  const soloPassFeatures3 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features3.png`;
  const soloPassFeatures4 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features4.png`;
  const soloPassFeatures5 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features5.png`;
  const soloPassFeatures6 = `${cloudfrontBaseUrl}/homepage-24/solo-pass/soloPass-features6.svg`;

  return (
    <Flex paddingBottom={[10, 24]} paddingX={[0, 16, 16, 8]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        px={[4, 0]}
      >
        <H2
          style={{
            color: colors.iceberg,
          }}
        >
          Our key features
        </H2>
        <Flex mt="12px" mb={9} justify="center">
          <H4 center lineHeight={['1.2']} color={colors.iceberg}>
            For those who prefer to have more autonomy with personal accounts:
          </H4>
        </Flex>
      </Flex>

      <TextWithImageRowDarkBg
        tag="personal accounts"
        title={`Free personal account`}
        body={`The only way you can sign up for a personal account without a Joint Account! Elevate your personal banking experience with Zeta's complete control over your finances with user-friendly features to manage money your way.`}
        imageOnRight={true}
        image={soloPassFeatures1}
        mobileImage={soloPassFeatures1}
        smallerText={true}
      />

      <TextWithImageRowDarkBg
        tag="goals"
        title={`Save like a pro`}
        body={
          <Box>
            Achieve your goals with ease! Divide your savings into envelopes so that you
            know exactly how your tracking towards your dreams — and earn interest on your
            entire account as a VIP
            <Link to={`${pathname}#zeta-disclosure`} color={colors.greenPea}>
              ²
            </Link>{' '}
            with no limits.
          </Box>
        }
        imageOnRight={false}
        image={soloPassFeatures2}
        mobileImage={soloPassFeatures2}
        smallerText={true}
      />

      <TextWithImageRowDarkBg
        tag="automate your money"
        title={`Stress-free finances`}
        body={`Ensure your financial life runs smoothly with rules that automatically save, manage, and track your money.`}
        imageOnRight={true}
        image={soloPassFeatures3}
        mobileImage={soloPassFeatures3}
        smallerText={true}
      />

      <TextWithImageRowDarkBg
        tag="security"
        title={`Built with bank-grade security standards`}
        body={`Your Zeta account is FDIC-insured up to the standard maximum of $250,000 per depositor. Plus two-factor authentication & data encryption keep your data secure. We don't store your external login credentials and will never sell your data.`}
        imageOnRight={false}
        image={soloPassFeatures4}
        mobileImage={soloPassFeatures4}
        smallerText={true}
      />
      <TextWithImageRowDarkBg
        tag="security"
        title={`Built with bank-grade security standards`}
        body={`Your Zeta account is FDIC-insured up to the standard maximum of $250,000 per depositor. Plus two-factor authentication & data encryption keep your data secure. We don't store your external login credentials and will never sell your data.`}
        imageOnRight={true}
        image={soloPassFeatures5}
        mobileImage={soloPassFeatures5}
        smallerText={true}
      />
      <TextWithImageRowDarkBg
        tag="security"
        title={`Built with bank-grade security standards`}
        body={`Your Zeta account is FDIC-insured up to the standard maximum of $250,000 per depositor. Plus two-factor authentication & data encryption keep your data secure. We don't store your external login credentials and will never sell your data.`}
        imageOnRight={false}
        image={soloPassFeatures6}
        mobileImage={soloPassFeatures6}
        smallerText={true}
      />
    </Flex>
  );
};

export default KeyFeatures;
