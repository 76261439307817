import React from 'react';
import { Flex, Image, Box, Text } from '@chakra-ui/core';
import { TextLink } from '@zeta';

const ContentResource = ({
  variant,
  imageSource,
  title,
  description,
  ctaUrl,
  customTitleSize,
  titleBreak = false,
  center = false,
}) => {
  const ultraMega = variant === 'ultraMega';

  return (
    <Flex
      direction={['column', ultraMega ? 'row' : 'column']}
      px={[6, 0, 0, 0]}
      align={'flex-start'}
      flex={'1'}
      style={{ gap: '24px' }}
    >
      <Image
        loading="eager"
        width={['100%', '100%', ultraMega ? '70%' : '100%']}
        maxWidth={['100%', '100%', ultraMega ? '570px' : '300px']}
        src={imageSource}
      />

      <Flex
        justify={center ? 'center' : 'auto'}
        pl={[0, ultraMega ? '24px' : 0, ultraMega ? '48px' : 0]}
        pt={[0, ultraMega ? '12px' : 0, 0]}
        paddingRight={[3]}
        direction="column"
      >
        <Text
          paddingTop={[0]}
          paddingRight={
            titleBreak ? [0, '32px', '48px', '64px'] : [0, '12px', '28px', '32px']
          }
          fontWeight={600}
          fontSize={
            customTitleSize
              ? [...customTitleSize]
              : ultraMega
              ? ['24px', '24px', '24px', '32px']
              : ['18px', '18px', '24px', '24px']
          }
          lineHeight={['1.2', '1.2', '1.3']}
        >
          {title}
        </Text>

        <Text
          marginTop={'16px'}
          width={'100%'}
          paddingRight={ultraMega ? '15%' : null}
          fontSize={['16px', '18px']}
        >
          {description}
        </Text>

        <Box mt="16px">
          <TextLink text="Read more" noNewTab to={ctaUrl} variant="primary" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ContentResource;
