import React from 'react';
import { Flex, Text, Image, Box, useMediaQuery } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import Wave1 from '../assets/Wave1';

// interface
// connector: "even | odd | none" // numbering starts at zero

const HowItWorksItem = ({
  image = '',
  title = '',
  body = '',
  connector = 'none',
  itemCount = 3,
}) => {
  const [isMobile] = useMediaQuery(`(max-width: 62em)`);
  const items = () => {
    switch (itemCount) {
      case 1:
        return '100%';
      case 2:
        return '50%';
      case 3:
        return '33.33%';
      case 4:
        return '25%';
      default:
        return '33.33%';
    }
  };
  return (
    <Flex
      direction={['row', 'row', 'row', 'column']}
      width={['100%', '100%', '100%', items()]}
      px={[0, 0, 0, 4]}
      pb={['24px', '24px', '24px', 0]}
      mb={[7, 7, 7, 0]}
    >
      <Flex
        align="center"
        justify={['flex-end', 'flex-end', 'flex-end', 'space-between']}
        paddingRight={[8, 8, 8, 0]}
        width={['35%', '45%', '45%', '100%']}
      >
        <Flex
          width={['90px', '90px', '90px', '100px']}
          height={['60px', '60px', '60px', '90px']}
          minWidth={['90px', '90px', '90px', '100px']}
          justify={['center', 'center', 'center', 'flex-start']}
          align="center"
        >
          <Image src={image} height={['unset', 'unset', 'unset', 'inherit']} />
        </Flex>

        {connector === 'even' && !isMobile ? (
          <>
            <Flex
              justifyContent="center"
              paddingLeft={'5%'}
              width={['100%', '50%', '30%', '100%']}
            >
              <Wave1 width={'100%'} />
            </Flex>
          </>
        ) : null}
        {connector === 'odd' && !isMobile ? (
          <>
            <Flex
              justifyContent="center"
              paddingTop={6}
              paddingLeft={'5%'}
              width={['100%', '70%', '60%', '100%']}
            >
              <Wave1 flip={true} width={'100%'} />
            </Flex>
          </>
        ) : null}
      </Flex>

      <Flex
        direction="column"
        marginTop={[2, 2, 2, '36px']}
        paddingRight={[4, 4, 4, 0]}
        width={['65%', '50%', '55%', 'auto']}
      >
        <Text fontSize="25px" fontWeight="600" pb="12px">
          {title}
        </Text>
        <Text
          fontWeight={theme.fontWeights.normal}
          color={colors.boulder}
          paddingRight={[0, '33%', '33%']}
        >
          {body}
        </Text>
      </Flex>
    </Flex>
  );
};

export default HowItWorksItem;
