import React, { useState } from 'react';
import { Flex, Box, Drawer, DrawerBody, DrawerContent } from '@chakra-ui/core';
import { HamburgerIcon } from '@chakra-ui/icons';
import { colors } from '@theme';
import LogoLink from './LogoLink';
import GetStartedBtn from './GetStartedButton';
import Hamburger from './Hamburger';

const SmallNav = ({ lightMode, homeNav, legal, bgColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const styles = {
    burger: {
      width: '2rem',
      height: '.25rem',
      borderRadius: '10px',
      backgroundColor: colors.black,
      transformOrigin: '1px',
      transition: `all 0.3s linear`,
    },
  };

  return (
    <>
      {!isOpen && (
        <Box
          paddingY={['1rem', '1rem']}
          paddingX={[0, '1rem']}
          as="nav"
          position="fixed"
          backgroundColor={bgColor}
          style={{
            top: 0,
            width: '100%',
            zIndex: 100,
          }}
        >
          <Flex pl="24px" pr="24px" justify="space-between" align="center">
            <LogoLink homeNav={homeNav} legal={legal} mobile lightMode={lightMode} />

            <Flex align="center">
              <Flex mr={6}>
                <GetStartedBtn />
              </Flex>
              <HamburgerIcon boxSize="2rem" onClick={toggleOpen} />
            </Flex>
          </Flex>
        </Box>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={toggleOpen}
        size="full"
        isFullHeight
      >
        {/* <DrawerOverlay /> */}
        <DrawerContent maxHeight="100vh" overflowY="auto" zIndex={'100'}>
          <DrawerBody padding={0} overflow={'hidden'}>
            <Hamburger
              toggleOpen={toggleOpen}
              legal={legal}
              homeNav={homeNav}
              lightMode={lightMode}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SmallNav;
