import React, { useState } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { colors } from '@theme';
import { APP_CONTENT_SMALL_DESKTOP_WIDTH } from '../styles/sizes';
import LargeNav from './LargeNav';
import SmallNav from './SmallNav';

// When adding, modifying or removing a nav item make sure you update the Hamburger
// and LargeNav components within this file to cover both desktop and mobile states.
// Anything you modify in this file needs to be updated on the zeta-blog side as well.
const Navigation = ({
  showGetStartedMobile = true,
  showGetStartedDesktop = true,
  mode = 'light',
  legal,
  transparent = false,
  customBackgroundColor = null,
  bgColorResult,
}) => {
  // state for is it transparent right now.
  const [isNavTransparent, setIsNavTransparent] = useState(transparent);

  const lightMode = mode === 'light';

  const homeNav = mode === 'home';

  let bgColor =
    transparent && isNavTransparent
      ? 'transparent'
      : homeNav
      ? colors.white
      : lightMode
      ? colors.white
      : colors.primary;

  if (customBackgroundColor !== null && !transparent) {
    bgColor = customBackgroundColor;
  }

  if (customBackgroundColor !== null && transparent) {
    bgColor = isNavTransparent ? customBackgroundColor : colors.white;
    bgColorResult && bgColorResult(bgColor);
  }

  const DesktopOnly = styled.div`
    @media (max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH}) {
      display: none;
    }
  `;
  const MobileOnly = styled.div`
    @media (min-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH}) {
      display: none;
    }
  `;
  return (
    <>
      <DesktopOnly>
        <LargeNav
          showGetStartedDesktop={showGetStartedDesktop}
          bgColor={bgColor}
          homeNav={homeNav}
          lightMode={lightMode}
          legal={legal}
        />
      </DesktopOnly>
      <MobileOnly>
        <SmallNav
          lightMode={lightMode}
          homeNav={homeNav}
          legal={legal}
          bgColor={bgColor}
        />
      </MobileOnly>
      {transparent && (
        <Waypoint
          topOffset={homeNav ? '-10%' : '-100%'}
          onEnter={() => {
            if (customBackgroundColor === null) {
              setIsNavTransparent(true);
            }
            if (customBackgroundColor !== null) {
              setIsNavTransparent(true);
            }
          }}
          onLeave={() => {
            if (customBackgroundColor === null) {
              setIsNavTransparent(false);
            }
            if (customBackgroundColor !== null) {
              setIsNavTransparent(false);
            }
          }}
        />
      )}
    </>
  );
};

export default Navigation;
