import React from 'react';

import { colors } from '@theme';

export default function Wave1({
  fill = colors.lightGray,
  flip = false,
  width = '231px',
}) {
  return (
    <svg
      width={width}
      height="18"
      viewBox="0 0 231 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={flip ? 'rotate(180)' : 'none'}
    >
      <path
        d="M2 9.43125C58.8854 1.54875 170.625 -4.69156 231 18"
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="10 10"
      />
    </svg>
  );
}
